export default (to, from, next) => {

  if (!$cookies.get('role') || $cookies.get('role') == 'guest') {
    $cookies.set('role', 'guest', '1y')
    return next("login")
  }
  else if (
    $cookies.get('role') === 'superadmin' ||
    $cookies.get('role') === 'admin' ||
    $cookies.get('role') === 'staff' ||
    $cookies.get('role') === 'user'
  ) {
    return next()
  }
  else {
    return next("catalogue")
  }
};

// if(
//   $cookies.get('role')==='superadmin'||
//   $cookies.get('role')==='admin'||
//   $cookies.get('role')==='staff'
// ){