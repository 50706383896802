import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Superboard from "@/pages/Matador/Superboard/Superboard.vue";
// import Dashboard from "@/pages/Dashboard.vue";
// import Vehicles from "@/pages/Matador/Vehicle/VehiclesPage.vue";
import Alert from "@/pages/Matador/Alert/AlertPage.vue";
import Login from "@/pages/Matador/Auth/LoginPage.vue";
import PdfReport from "@/pages/Matador/Reports/PdfReport.vue";
import Reports from "@/pages/Matador/Reports/ReportsPage.vue";
import Track from "@/pages/Matador/Track/TrackPage.vue";
import Users from "@/pages/Matador/Users/UsersPage.vue";
// import Monitoring from "@/pages/Matador/Monitoring/MonitoringPage.vue";
import Monitoring from "@/pages/Matador/Monitoring/Page.vue";

import GeofencePage from "@/pages/Matador/Geofence/GeofencePage.vue";
import Registration from "@/pages/Matador/Register/Registration.vue";
import Coba from "@/pages/Matador/Rental/CobaManage.vue";
import DashboardRental from "@/pages/Matador/Rental/DashboardEtalase.vue";
import MakeOrder from "@/pages/Matador/Rental/EtalaseMakeOrder.vue";
import MakeOrderRedirectCheckout from "@/pages/Matador/Rental/EtalaseMakeOrderRedirectCheckout.vue";
import Catalogue from "@/pages/Matador/Rental/EtalasePage.vue";
import Invoice from "@/pages/Matador/Rental/InvoicePage.vue";
import ManageCatalogue from "@/pages/Matador/Rental/ManageEtalasePage.vue";
import RentalOrderList from "@/pages/Matador/Rental/OrderList.vue";
import PdfInvoice from "@/pages/Matador/Rental/PDFInvoice.vue";
import RentalSetting from "@/pages/Matador/Rental/RentalSettingPage.vue";
import RentList from "@/pages/Matador/Rental/RentList.vue";
import Setting from "@/pages/Matador/Setting/SettingPage.vue";
import UserPrivilagePage from "@/pages/Matador/UserPrivilage/UserPrivilagePage.vue";


import DashboardRouter from '@/pages/Matador/Dashboard/router';
import DeviceRouter from '@/pages/Matador/Device/router';
import DriverRouter from '@/pages/Matador/Driver/router';
import GeneratorRouter from '@/pages/Matador/Generator/router';
import TeltonikaRouter from '@/pages/Matador/Teltonika/router';
import VehicleRouter from '@/pages/Matador/Vehicle/router';

//Role
import roleDashboard from "../roles/roleDashboard.js";


let firstMenu;
if ($cookies.get('role') === 'superadmin') {
  firstMenu = "/superboard"
}
else {
  firstMenu = "/dashboard"
}
const routes = [
  {
    path: "/",
    component: DashboardLayout,

    redirect: firstMenu,
    children: [
      {
        path: "superboard",
        name: "superboard",
        beforeEnter: roleDashboard,
        component: Superboard
      },

      DashboardRouter(),

      {
        path: "alert",
        name: "alert",
        // beforeEnter: roleAlert,
        component: Alert
      },
      {
        path: "reports",
        name: "reports",
        // beforeEnter: roleReports,
        component: Reports
      },
      {
        path: "tracks",
        name: "tracks",
        // beforeEnter: roleTrack,
        component: Track
      },


      DeviceRouter(),
      DriverRouter(),
      GeneratorRouter(),
      TeltonikaRouter(),


      {
        path: "users",
        name: "users",
        // beforeEnter: roleUsers,
        component: Users
      },
      {
        path: "dashboardRental",
        name: "DashboardRental",
        // beforeEnter: roleEtalase,
        component: DashboardRental
      },
      {
        path: "rentalSetting",
        name: "RentalSetting",
        // beforeEnter: roleEtalase,
        component: RentalSetting
      },
      {
        path: "catalogue",
        name: "Catalogue",
        // beforeEnter: roleEtalase,
        component: Catalogue
      },
      {
        path: "manageCatalogue",
        name: "Manage Catalogue",
        // beforeEnter: roleManageEtalase,
        component: ManageCatalogue
      },
      {
        path: "makeOrder",
        name: "Etalase Make Order",
        component: MakeOrder
      },
      {
        path: "makeOrderRedirect",
        name: "Etalase Make Order Redirect Checkout",
        component: MakeOrderRedirectCheckout
      },
      {
        path: "orderList",
        name: "Order list",
        // beforeEnter: roleOrderList,
        component: RentalOrderList
      },
      {
        path: "privilage",
        name: "privilage",
        // beforeEnter: roleUserPrivilagePage,
        component: UserPrivilagePage
      },
      {
        path: "monitoring",
        name: "monitoring",
        // beforeEnter: roleMonitoring,
        component: Monitoring
      },

      VehicleRouter.Detail(),
      VehicleRouter.Default(),
      VehicleRouter.Satellite(),

      {
        path: "geofence",
        name: "geofence",
        // beforeEnter: roleGeofencePage,
        component: GeofencePage
      },
      {
        path: "setting",
        name: "setting",
        component: Setting
      },
      {
        path: "rentList",
        name: "rent-list",
        // beforeEnter: roleSetting,
        component: RentList
      },
      {
        path: "invoice/:order_id",
        name: "invoice",
        // beforeEnter: roleSetting,
        component: Invoice,
        props: true,
      },
      {
        path: "pdfInvoice/:order_id",
        name: "pdfInvoice",
        // beforeEnter: roleSetting,
        component: PdfInvoice,
        props: true,
      },
      {
        path: "coba",
        name: "coba",
        // beforeEnter: roleSetting,
        component: Coba
      },
      {
        path: "registration",
        name: "Registration",
        // beforeEnter: roleSetting,
        component: Registration
      }

    ]
  },
  { path: "/login", component: Login },
  { path: "/print-report", component: PdfReport },
  { path: "*", component: NotFound }
];

export default routes;
