import { FormGroupInput, Card, DropDown, Button } from "../components/index";

import "bootstrap-table/dist/bootstrap-table.min.css";
import "./jquery.js";
import "bootstrap-table/dist/bootstrap-table";
import BootstrapTable from "bootstrap-table/dist/bootstrap-table-vue.esm";
import Dropdown from "vue-simple-search-dropdown";
import "bootstrap-table/dist/extensions/export/bootstrap-table-export";
import "@/components/Matador/Loader";
import "@/components/Matador/Checkbox";
import "@/components/Matador/EditIcon";
import "@/components/Matador/DeleteIcon";
import MatadorButton from "@/components/Matador/Button";
import HeadingV1 from "@/components/Matador/HeadingV1.vue";
import SearchBar from "@/components/Matador/SearchBar";
import VueToast from "vue-toast-notification";
import Multiselect from "vue-multiselect";
import VueGoogleMap from "vuejs-google-maps";
import VueTimepicker from "vue2-timepicker";
import VueCarousel from "vue-carousel";
import 'vue-range-component/dist/vue-range-slider.css'
import VueRangeSlider from 'vue-range-component'
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vuejs-google-maps/dist/vuejs-google-maps.css";
import "vue2-timepicker/dist/VueTimepicker.css";

import { BTooltip } from 'bootstrap-vue'


import config from '@/config'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */


const GlobalComponents = {
  install(Vue) {
    Vue.component("timepicker", VueTimepicker);
    Vue.component("fg-input", FormGroupInput);
    Vue.component("drop-down", DropDown);
    Vue.component("card", Card);
    Vue.component("p-button", Button);
    Vue.component("bs-table", BootstrapTable);
    Vue.component('b-tooltip', BTooltip);
    Vue.use(VueToast, {
      position: "bottom"
    });
    Vue.component("autocomplete", Dropdown);
    Vue.use(VueGoogleMap, {
      load: {
        apiKey: config.google.api_key,
        libraries: ["places"]
      }
    });
    Vue.component("matador-button", MatadorButton);
    Vue.component("matador-search", SearchBar);
    Vue.component("heading-v1", HeadingV1);
    Vue.component("multiselect", Multiselect);
    Vue.use(VueCarousel);
    Vue.use(VueRangeSlider);
  }
};

export default GlobalComponents;
