import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import {app} from '@/config'

let instance = Bugsnag
if (app.bugsnag) {
  Bugsnag.start({
      // apiKey: 'db37b3ab947675276cabafa3c16b3776',
      apiKey: app.bugsnag_key,
      releaseStage: process.env.NODE_ENV,
      plugins: [new BugsnagPluginVue()],
  });
} else {
  instance = {
    notify(e) {
      console.log(e)
    }
  }
}

export default instance;