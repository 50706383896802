import { directive as vClickOutside } from "vue-clickaway";
import dayjs from "dayjs";
import config from '@/config'
/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", vClickOutside);
    Vue.filter("date", function(value) {
      return dayjs(value).format("DD MMM YYYY");
    });
    Vue.filter("datetime", function(value) {
      return dayjs(value).format("MMM DD, YYYY HH:mm:ss");
    });
    Vue.filter("assets", val => {
      let url = val.replace(/http:\/\/\d+\.\d+\.\d+\.\d+/g, config.app.base_url);
      url = url.replace(/http:\/\/\d+\.\d+\.\d+\.\d+\:\d+/g, config.app.base_url);
      return url;
    });
    Vue.prototype.t = (str) => str
  }
};


window.initCallback = (interval, timeout, callback) => {
  timeout = timeout || 0
  timeout = timeout * 1000

  let endTry = Date.now() + timeout

  const init = setInterval(() => {
    if (Date.now() >= endTry)
      return clearInterval(init)

    try {
      callback( () => clearInterval(init) )
    } catch(e) { console.log(e) } 

  }, interval)
}


export default GlobalDirectives;
